<template>
  <v-expansion-panels v-model="active" @change="$emit('update:isActive', active ?? 1)">
    <v-expansion-panel>
      <v-expansion-panel-header class="px-5 py-4 background rounded-b-md">
        <v-row no-gutters align="center">
          <v-col class="pa-0">
            <span class="subtitle-2 grey-500--text font-weight-semibold">
              Configuración personalizada
              <span class="caption grey-500--text ml-3">Opcional</span>
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-5 background mt-n2 pb-8">
        <v-switch v-model="modelValue.send_mail" flat label="Enviar un email" hide-details color="success" :ripple="false"></v-switch>
        <!-- email -->
        <div class="pt-5 text-left" v-if="modelValue?.send_mail">
          Escribe la casilla (máximo 3 mails)
          <v-col class="px-0">
            <v-autocomplete
              v-model="modelValue.emails"
              hide-details
              single-line
              color="lightBlue"
              dense
              outlined
              :items="membersList"
              multiple
              :chips="false"
              item-text="email"
              item-value="email"
              @input="verifyEmails"
              item-disabled="customDisabled"
              :prefix="modelValue?.emails?.length >= 1 ? `${modelValue?.emails?.length === 1 ? `${modelValue?.emails?.length} seleccionado` : `${modelValue?.emails?.length} seleccionados`}` : ''"
              no-data-text="No existen miembros para mostrar"
            >
              <template v-slot:selection="{ props }">
                <span v-if="props" style=""></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-row class="ml-1" no-gutters>
            <v-col class="pa-0">
              <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in modelValue?.emails" :key="`${member}${i}`" close @click:close="memberRemove(member, i)" color="lightBlue" small label close-icon="mdi-close">
                {{member?.email ?? member }}
              </v-chip>
            </v-col>
          </v-row>
        </div>
        <!--end email -->

        <!-- webhook --
        <div class="text-left">
          <v-switch v-model="activeWebhook" flat label="Activar un webhook" hide-details color="success" :ripple="false"></v-switch>
          <div class="pt-5" v-if="activeWebhook">
            Escribe la URL de conexión
            <v-col class="px-0">
              <v-text-field v-model="modelValue.webhook" outlined single-line dense hide-details />
            </v-col>
          </div>
        </div>
        <!- end webhook -->

        <!-- SFTP --
        <div class="text-left">
          <v-switch v-model="activeSftp" flat label="Publicar el documento en un SFTP" hide-details color="success" :ripple="false"></v-switch>
          <v-col class="pt-5 pa-0" v-if="activeSftp">
            Escribe la URL
            <v-col class="px-0">
              <v-text-field v-model="modelValue.sftp" outlined single-line dense  hide-details />
            </v-col>
            <v-col class="d-flex pa-0">
              <div>
                Subir certificado
                <v-icon color="grey-700" small>mdi-information</v-icon>
              </div>
              <div class="ml-4">
                <v-col cols="12" class="py-0 mt-n3">
                  <v-file-input class="input-file-automation" type="file" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                </v-col>
              </div>
            </v-col>
          </v-col>
        </div>
        <!- end SFTP -->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    rule: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    active: 1,
    membersList: [],
    rulesNotificationList: [],
    modelValue: {
      emails: [],
      send_mail: false,
      sftp: null,
      stage: ''
    },
    byList: [
      {
        text: 'Reclamo contenido del documento',
        value: 'RCD'
      },
      {
        text: 'Reclamo por falta parcial de mercaderías',
        value: 'RFP'
      },
      {
        text: 'Reclamo por falta total de mercaderías',
        value: 'RFT'
      }
    ]
  }),
  computed: {
    ...mapState({
      rules_notificationList: state => state.purchases.rules_notificationList
    })
  },
  watch: {
    modelValue: {
      handler (val) {
        this.$emit('update:model', { ...val, stage: this.rule.type })
      },
      deep: true
    },
    active (val) {
      if (!val) {
        this.membersList = this.membersList.map((item) => {
          return {
            ...item,
            customDisabled: false
          }
        })
      }
    },
    rules_notificationList: {
      handler (val) {
        if (val?.length) {
          let list = val.filter((item) => item.group_rules === this.rule.id) ?? []
          if (list?.length) {
            const obj = list.find(({stage}) => stage === this.rule.type) ?? {}
            this.modelValue = { ...obj, send_mail: true, stage: this.rule.type }
          }
        }
      },
      inmediate: true
    },
    '$store.state.accounts.membersList': {
      handler (val) {
        this.membersList = val.map((item) => {
          return {
            ...item,
            customDisabled: false
          }
        })
      },
      inmediate: true,
      deep: true
    }
  },
  created () {
    this.active = this.isActive
  },
  methods: {
    verifyEmails () {
      this.modelValue.emails.forEach((item, index) => {
        this.membersList.forEach((m) => {
          if (index >= 2) {
            if (item.email !== m.email) m.customDisabled = true
          } else {
            m.customDisabled = false
          }
        })
      })
    },
    memberRemove (item) {
      const index = this.modelValue.emails.indexOf(item)
      if (index >= 0) this.modelValue.emails.splice(index, 1)
    }
  }
}
</script>